// Foundation JavaScript
// Documentation can be found at: http://foundation.zurb.com/docs
$(document).foundation();

$('.sword-icon').click(function() {

  $('body').toggleClass("sword");
  
});


$('.ness-icon').click(function() {

  $('body').toggleClass("ness");
  
});


$('.ragnell-icon').click(function() {

  $('body').toggleClass("ragnell");
  
});
// Animated Menu Icon
// $('.container').on('click',function(){
//   $(this).toggleClass('expanded');
// });

// // Modal 
// $('a.close, .reveal-modal-bg').on('click', function() {
//   $('#firstModal').foundation('reveal', 'close');
//   // $('.nav-button').toggleClass('ex');
//   $('.reveal-modal-bg').toggleClass('modal-displayed');
//   // $(this).text(($(this).text() == 'Close') ? 'Menu' : 'Close');
  
// });

// var $overlay = $('<div id="overlay"><a href="#" id="close"><i class="fa fa-times"></i></a></div>');
var $overlay = $('<div id="overlay"><div id="close"><i class="fa fa-times"></i></div></div>');
var $menulist = $(".menulist");



$overlay.append($menulist);
$('body').append($overlay);

//show overlay
$("div[class='container']").on("click", function() {
  //event.preventDefault();
  $('.container').toggleClass('expanded');
  $('.menulist').removeClass('menuhide');
  $overlay.fadeIn();
});

//hide overlay
$(".close").on("click", function() {
  //$overlay.on("click", function(){
  $overlay.fadeOut();
  $('.container').removeClass('expanded');
  // $('.menulist').removeClass('menuhide');


});

$("body").on("click", "#overlay", function() {
  $overlay.fadeOut();
  $('.container').removeClass('expanded');
});







/////// smooth scroll ////////

$(function() {
  $('a[href*=\\#]:not([href=\\#])').click(function() {
    if (location.pathname.replace(/^\//,'') == this.pathname.replace(/^\//,'') && location.hostname == this.hostname) {
      var target = $(this.hash);
      target = target.length ? target : $('[name=' + this.hash.slice(1) +']');
      if (target.length) {
        $('html,body').animate({
          scrollTop: target.offset().top
        }, 1000);
        return false;
      }
    }
  });
});

